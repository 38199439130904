import styles from '../Calendar.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getDate, getDayStart, getDayEnd } from '@wojtekmaj/date-utils';
import {
  activeStartDateCalculatedDataPropType, edgeDateValueValidator, valuePropType,
  valueTypePropType, tileContentPropType,
} from '../propTypes';

import { getDayOfWeekIndex, getTilesGroup, isTileDisabled, getTileClassNames, isWeekend, getHoliday, getLeadTimeDate } from '../helpers';

const Days = ({
  activeStartDateCalculatedData,
  hoveredDate,
  firstDayOfWeek,
  formatLongDate,
  maxDate,
  minDate,
  onClick,
  onMouseOver,
  showFixedNumberOfWeeks,
  showNeighboringMonth,
  shouldDisableTile,
  tileContent,
  value,
  valueType,
  holidays,
  leadTimeDates,
}) => {
  const tilesGroup = useMemo(
    () => getDateTileGroups(activeStartDateCalculatedData, firstDayOfWeek, showFixedNumberOfWeeks, showNeighboringMonth),
    [activeStartDateCalculatedData, firstDayOfWeek, showFixedNumberOfWeeks, showNeighboringMonth],
  );

  const transformedMinDate = useMemo(() => minDate && getDayStart(minDate), [minDate]);
  const transformedMaxDate = useMemo(() => maxDate && getDayEnd(maxDate), [maxDate]);

  return (
    <section className={styles.days}>
      {tilesGroup.map((date, idx) => {
        const isDisabled = isTileDisabled(
          date,
          activeStartDateCalculatedData.date,
          transformedMinDate,
          transformedMaxDate,
          'month',
          shouldDisableTile,
        );
        const isNeighboringMonth = date.getMonth() !== activeStartDateCalculatedData.monthIndex;

        let classNames = getTileClassNames(date, 'day', value, valueType, hoveredDate);
        if (isNeighboringMonth)
          classNames += ` ${styles.neighboringMonth}`;

        if (isWeekend(date, activeStartDateCalculatedData.calendarType))
          classNames += ` ${styles.weekend}`;

        //3.5. Validation of the delivery window on checkout - Start
        if (isDisabled)
          classNames += ' disabled';
        else
          classNames += ' allowed';

        if (holidays && holidays.length > 0) {
          const currentDateHoliday = getHoliday(date, holidays);
          if (currentDateHoliday != null)
            classNames += ' holiday';
        }

        if (leadTimeDates && leadTimeDates.length > 0) {
          const currentDateLeadTimeDate = getLeadTimeDate(date, leadTimeDates);
          if (currentDateLeadTimeDate != null)
            classNames += ' leadTime';
        }
        //3.5. Validation of the delivery window on checkout - End

        const handleMouseOver = !isDisabled && onMouseOver ? event => onMouseOver(date, event) : null;

        return (
          <button
            key={+date}
            tabIndex={isDisabled || isNeighboringMonth ? -1 : null}
            className={`${styles.day} ${classNames}`}
            aria-disabled={isDisabled}
            aria-keyshortcuts="ArrowUp ArrowDown ArrowLeft ArrowRight Space Enter"
            onClick={!isDisabled && onClick ? event => onClick(date, event) : null}
            onFocus={handleMouseOver}
            onMouseOver={handleMouseOver}
            type="button"
            style={
              !(showFixedNumberOfWeeks || showNeighboringMonth) && idx === 0
                ? { marginLeft: (100 * activeStartDateCalculatedData.dayOfWeekIndex) / 7 + '%' }
                : null
            }
          >
            <abbr aria-label={formatLongDate(date)}>
              {getDate(date)}
            </abbr>
            {typeof tileContent === 'function' ? tileContent(activeStartDateCalculatedData.date, date, 'month') : tileContent}
          </button>
        );
      })}
    </section>
  );
};

Days.propTypes = {
  activeStartDateCalculatedData: activeStartDateCalculatedDataPropType,
  hoveredDate: PropTypes.instanceOf(Date),
  firstDayOfWeek: PropTypes.number,
  formatLongDate: PropTypes.func.isRequired,
  maxDate: edgeDateValueValidator,
  minDate: edgeDateValueValidator,
  onClick: PropTypes.func.isRequired,
  onMouseOver: PropTypes.func,
  showFixedNumberOfWeeks: PropTypes.bool,
  showNeighboringMonth: PropTypes.bool,
  shouldDisableTile: PropTypes.func,
  tileContent: tileContentPropType,
  value: valuePropType,
  valueType: valueTypePropType,
  holidays: PropTypes.any,
  leadTimeDates: PropTypes.any,
};

export default Days;

function getDateTileGroups(activeStartDateCalculatedData, firstDayOfWeek, showFixedNumberOfWeeks, showNeighboringMonth) {
  const { year, dayOfWeekIndex, daysInMonth, monthIndex, calendarType } = activeStartDateCalculatedData;
  const start = ((showFixedNumberOfWeeks || showNeighboringMonth) ? -dayOfWeekIndex : 0) + 1;

  let end;
  if (showFixedNumberOfWeeks) {
    end = start + (6 * 7) - 1;
  } else {
    end = daysInMonth;
    if (showNeighboringMonth) {
      const activeEndDate = new Date();
      activeEndDate.setFullYear(year, monthIndex, daysInMonth);
      activeEndDate.setHours(0, 0, 0, 0);
      const daysUntilEndOfTheWeek = 7 - getDayOfWeekIndex(activeEndDate, firstDayOfWeek, calendarType) - 1;
      end += daysUntilEndOfTheWeek;
    }
  }

  return getTilesGroup(start, end)
    .map(day => {
      const date = new Date();
      date.setFullYear(year, monthIndex, day);
      date.setHours(0, 0, 0, 0);
      return date;
    });
}
